.pieceList{
    margin-bottom: 10px;
}

.pieceNombre{
    margin: 0 20px 5px;
    padding: 0 10px;
    background-color: #e7e7e7;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pieceNombre:hover {
    background-color:#d5d4d4;
}

.nombre{
    padding-right: 20px;
}

.piecebox{
    display: flex;
    justify-content: start;
    align-items: center;
}

.supprimerPiece{
    display: flex;
    justify-content: end;
    align-items: center;
}

.supprimerPiece .MuiButtonBase-root{
    width: 80%;
    height: 80%;
    padding: 0;
}

.ajoutePiece{
    display: flex;
    justify-content: center;
}

.piece .styleBtnDevis .MuiButtonBase-root {
    width: 100%;
    margin: 0 20px 20px;
    font-weight: normal;
    height: 40px;
}

.ajouteNiveau{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.btnAjouteNiveau{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ajoutePieceTooltip.MuiTooltip-tooltip, .ajoutePieceTooltip .MuiTooltip-arrow::before {
    background: rgba(239, 239, 239, 0.5);
    backdrop-filter: blur(10px);
    color: black;
    font-family: AvenirNext;
    font-size: 16px;
}

.listePiece {
    width: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.listePiece > div{
    padding: 5px;
}

.listePiece > div:hover{
    background: white;
    border-radius: 5px;
    cursor: pointer;
}

.btnAjouteNiveau .MuiButtonBase-root {
    font-weight: normal;
    background: #f8e700;
    color: black;
    width: 50px;
    height: 50px;
}

.btnAjouteNiveau .MuiButtonBase-root:hover {
    background: rgba(248,231,0,0.5);
}