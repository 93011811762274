.exemplePrix{
    width: 100%;
    min-height: 300px;
    margin: auto;
}

.elementHabitation{
    justify-items: center;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
/*
.triangleCategorie{
    height: 0;
    width: 0;
    position: absolute;
    border-style: solid;
    border-width: 0 50px 50px 50px;
}
*/
.triangleCategorie{

}
.triangleGauche{
    left: calc(((100% / 3 *1)/2));
}

.triangleCentre{
    left: calc((100% /2));
}

.triangleDroite{
    right: calc(((100% / 3 )/2));
}

.texteDetailsPrix{
    width: 80%;
    min-height: 200px;
    border: 1px solid;
    margin: 50px auto auto;
}

/* Responcive */

@media (max-width: 912px) {
    .texteDetailsPrix{
        width: 90%;
        margin: 25px auto auto;
    }
    .triangleCategorie{
        border-width: 0 25px 25px 25px;
    }
    .triangleGauche{
        left: calc(((100% / 3 *1)/2) - 25px);
    }

    .triangleCentre{
        left: calc((100% /2) - 25px);
    }

    .triangleDroite{
        right: calc(((100% / 3 )/2) - 25px);
    }

}