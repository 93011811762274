.configNiveau .piece{
    justify-content: center;
}
.titreNiveau{
    font-weight: bold;
    font-size: 30px;
    margin: 20px 0;
    text-align: center;
    color: #d90368;
}

.configPieceCentre{
    width: 100%;
}

.btnSuivantStyle .styleBtnDevis .MuiButtonBase-root {
    width: 30%;
}

.positionFleche{
    display: flex;
    justify-content: center;
}

.MuiTypography-root.MuiTypography-body1{
    font-family: AvenirNext, sans-serif;
    font-size: 20px;
}

.MuiFormGroup-root.MuiFormGroup-row{
    flex-direction: column;
}

.checkGeneralePosition{
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin:20px auto;
    background: #e7e7e7;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary{
    padding: 0;
    margin-left: -2px;
}

.checkGenerale{
    display: flex;
    justify-content: start;
    align-items: center;
}

.inputCoffret{
    width: 50px;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    margin-right: 10px;
    border: 1px black solid;
    font-size: 1em;
}

/* Responsive */

@media (max-width: 912px) {
    .btnSuivantStyle .styleBtnDevis .MuiButtonBase-root {
        margin: 0 5px;
        height: 35px;
        font-size: 0.8em;
    }
    .btnSuivantStyle .styleBtnDevis .MuiButtonBase-root {
        width: 175px;
    }
    .checkGenerale{
        flex-wrap: wrap;
    }
    .inputCoffret{
        margin-bottom: 5px;
    }
    .configNiveau .devisTaillePage{
        width: 100%;
    }
    .positionFleche{
        justify-content: space-between;
    }
}
