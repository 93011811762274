.services{
    min-height: 500px;
}

.services >div{
    padding-top: 50px;
}

.elementServices{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    transform: translateX(-2000px);
    opacity: 0;
    transition: 1.5s all ease;
}
.elementServices.active{
    transform: translateY(0);
    opacity: 1;
    transition: 2s all ease;
    padding: 20px 0;
}
/* Responcive */

@media (max-width: 912px) {
    .services{
        min-height: 300px;
    }
    .services >div{
        padding-top: 0;
    }
    .elementServices{
        transition: 1s all ease;
        transform: translateX(0);
        opacity: 1;
    }
    .elementServices.active{
        transition: 1s all ease;
        padding: 0;
    }
}