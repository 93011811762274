.piedDePage{
    background: #0A90C9;
    min-height: 300px;
    clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);    /* Pour mettre une diagonale au container de fond */
    padding-top: 10px;
}

.piedDePage .logo{
    height: 80px;
    margin: 20px;
}

.elementPiedDePage{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 40px 0 40px;
}
.InfoContact{
    display: flex;
    margin-bottom: 50px;
}

.contact{
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.PositionInfoContact{
    margin-left: 20px;
}

.PositionInfoContact >div{
    padding-bottom: 5px;
}

.reseauxSociaux{
    grid-column: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.reseauxSociaux >a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
}

.textReseau{
    font-size: 25px;
}

.logoRS{
    width: 30px;
    margin-right: 10px;
}

.piedDePage a:-webkit-any-link{
    color: white;
}

.conditions{
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1 / 6;
    margin: 10px;
}

.conditions >div{
    margin: 10px;
    font-size: 16px;
    text-align: center;
}

.copyright{
    grid-column: 1 / 6;
    margin: auto auto 20px;
    font-size: 16px;
    text-align: center;
}

/* Responcive */

@media (max-width: 912px) {
    .elementPiedDePage{
        margin: 0 20px;
        display: flex;
        flex-direction: column;
    }
    .piedDePage .logo{
        margin-top: 40px;
    }
    .InfoContact{
        margin-bottom: 10px;
    }
    .contact{
        display: flex;
        flex-direction: column;
    }
    .textReseau{
        font-size: 15px;
    }
    .reseauxSociaux{
        margin: 20px 0;
    }
    .logoRS{
        width: 20px;
    }
    .InfoContact .MuiSvgIcon-root{
        font-size: 20px;
    }
    .conditions{
        font-size: 0.7em;
    }
    .conditions >div{
        font-size: 10px;
    }
    .copyright{
        font-size: 10px;
    }
}