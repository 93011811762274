.configPiece{
    position: relative;
    background: white;
    border-radius: 10px;
    width: 100%;
    min-height: 400px;
}

.configPiece .configPieceTitre{
    color: black;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 900;
    padding: 20px;
    font-family: AvenirNextBold;
    display: flex;
    align-items: center;
}

.boxConfigPieceTitre{
    display: flex;
    justify-content: center;
    align-items: center;
}

.etapePiece{
    position: absolute;
    background: #d90368;
    padding: 10px;
    width: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    border-radius: 10px;
    color: white;
    margin: 20px;
    top: 0;
    right: 0;
}

.configPosition {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
}

.configPieceInformation{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnCircuit .styleBtnDevis .MuiButtonBase-root {
    width: 50%;
    margin: 0 0 20px;
    font-weight: normal;
    height: 40px;
    align-items: center;
    background-color: #0a90c9;
    color: white;

}

.configPiece .styleBtnDevis .MuiButtonBase-root:hover {
    background-color: #0879a9;
}

.btnFlottantPieceFixed{
    position: fixed;
    bottom: 70px;
    width: 100%;
    left: 0;
}

.btnSuivantStyle .btnFlottantPieceFixed .MuiButtonBase-root {
    width: 300px;
}

.positionEclairageZone{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.eclairage{
    display: flex;
    justify-content: center;
}

.styleEclairage{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.texteLongPiece{
    margin-right: 10px;
}

.flouTuto{
    filter: blur(10px);
    height: 100vh;
    overflow: hidden;
}

/* Responsive */

@media (max-width: 912px) {
    .configPiece {
        width: 100%;
    }
    .configPosition{
        flex-wrap: wrap;
    }
    .btnCircuit .styleBtnDevis .MuiButtonBase-root {
        width: 100%;
    }
    .configPiece .configPieceTitre{
        padding: 10px 0;
        text-align: center;
        font-size: 20px;
        flex-direction: column;
    }
    .boxConfigPieceTitre{
        flex-wrap: wrap;
        justify-content: center;
    }
    .etapePiece{
        position: unset;
        font-size: 18px;
        width: 100px;
        display: flex;
        justify-content: center;
        margin: auto auto 10px;
    }
    .btnFlottantPieceFixed{
        bottom: 60px;
    }
    .btnSuivantStyle .btnFlottantPieceFixed .MuiButtonBase-root {
        width: 170px;
    }
}