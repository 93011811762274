.taillePage{
    width: 80%;
    margin: 120px auto auto;
    padding-bottom: 50px;
    min-height: 700px;
}

@media (max-width: 1200px) {
    .taillePage{
        min-height: 300px;
    }
}

@media (max-width: 850px) {
    .taillePage{
        margin-top: 200px;
        min-height: 100px;
    }
}