.piece {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 20px;
    gap: 20px;
    padding-bottom: 50px;
}

.niveau{
    background: white;
    border-radius: 10px;
    width: calc((100% - 20px * (2 - 1)) / 2);
    min-height: 400px;
    position: relative;
}

/* Responsive */

@media (max-width: 912px) {
    .niveau{
        width: 100%;
    }
    .piece{
        margin: 5px;
    }

}

