.Pcontact{
    background: #0A90C9;
    width: 100%;
    min-height: 100%;
}

.infoContact{
    min-height: 500px;
    margin-top: 150px;
    margin-bottom: 80px;
}

.margintop{
    padding-top: 40px;
    padding-bottom: 40px;
}

.corps .ellipseRose{
    position: absolute;
    z-index: -4;
    width: 100%;
    height: 800px;
    background: rgba(217, 3, 104, 0.06);
    -moz-border-radius: 100px / 50px;
    -webkit-border-radius: 100px / 50px;
    border-radius:
            0px 2000px 0px 0px / 0px 800px 0px 0px;
}

.corps .ellipseBleu{
    position: absolute;
    z-index: -4;
    width: 100%;
    height: 800px;
    background: rgba(10, 144, 201, 0.07);
    border-radius:
            1900px 0px 0px 0px / 800px 500px 0px 0px;
}
