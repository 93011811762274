.EntrepotElement .logo{
    width:80%;
    margin-bottom: 20px;
}

.EntrepotElement{
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    padding-top: 20px;
}
