.etapecommencerDevis .devisTaillePage{
    width: 80%;
    min-height: 500px;
    margin: auto auto 50px;
}

.fontAssisant{
    background: rgba(255,255,255,0.5);
    border-radius: 20px;
}

.titreDevis{
    font-size: 30px;
    font-weight: normal;
}

.etapecommencerDevis .recupererDevis{
    display: flex;
    align-items: center;
    justify-content: center;
}

.positionHexaTexte{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inputCodeDevis{
    height: 35px;
    margin-right: 20px;
    width: 150px;
    padding: 0 10px;
}

.btnFleche{
    height: 41px;
}

.espaceBtnDevis{
    margin: 40px 0 10px 0;
}

.titreReprendreDevis{
    width: 350px;
    text-align: start;
    margin-right: auto;
    margin-left: auto;
}

.texteInfoDevis{
    padding: 40px 20px;
    font-size: 16px;
    text-align: center;
}

.positionRecupererDevis{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    padding-bottom: 20px;
}

.recupererDevis .MuiOutlinedInput-input{
    font-family: AvenirNext;
    font-size: 20px;
    width: 200px;
    height: 20px;
}

.recupererDevis .MuiFormHelperText-root
{
    font-family: AvenirNext;
}

.recupererDevis .MuiInputLabel-root
{
    font-family: AvenirNext;
    font-size: 16px;
}

.recupererDevis .MuiFormControl-root.MuiTextField-root{
    margin: 10px;
}

.boutonNumeroDevis .MuiButtonBase-root{
    background: #0a90c9;
    height: 52px;
}

.boutonNumeroDevis .MuiButtonBase-root:focus{
    background: #1976d2;
}
.boutonNumeroDevis .MuiLoadingButton-loadingIndicator{
    color: white;
}

/* Responsive */

@media (max-width: 912px) {
    .iconTaille {
        height: 40%;
    }
    .texteInfoDevis{
        padding: 0px 20px 40px 20px;
        font-size: 10px;
    }
    .titreDevis{
        font-size: 20px;
    }
    .fontAssisant{
        margin: 5px;
    }
    .etapecommencerDevis .devisTaillePage{
        width: 100%;
    }
}