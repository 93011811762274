.slider .MuiSlider-colorPrimary{
    width: 500px;
    height: 15px;
}
.slider .css-eg0mwd-MuiSlider-thumb{
    width: 30px;
    height: 30px;
}

/* Responsive */

@media (max-width: 912px) {
    .slider .MuiSlider-colorPrimary{
        width: 240px;
        display: flex;
        align-items: center;
        height: 10px;
    }
    .slider .css-eg0mwd-MuiSlider-thumb{
        width: 20px;
        height: 20px;
    }
}