.promo{
    background: #d90368;
    width: 100%;
    min-height: 50px;
    max-height: 300px;
    position: absolute;
    top: 120px;
    z-index: 1000;
    color: white;
    box-shadow: 0 10px 20px grey;
    text-align: center;
    overflow: auto;
}

.titrePromo{
    text-align: center;
    margin: 10px 0;
    text-transform: uppercase;
    font-size: 1.8em;
}

.listPromo{
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 30px;
}

.textePromo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0 10px;
}

.codePromo{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
}

/* Responsive */

@media (max-width: 912px) {
    .promo{
        width: 100%;
        max-height: 500px;
        text-align: center;
        position: unset;
        margin-top: 30px;
    }
}