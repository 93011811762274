.navigationBarre{
    position: fixed;
    z-index: 1001;
    top: 0;
    width: 100vw;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 20px rgba(0,0,0,0.5);
    background: white;
}

.nav .navigationBarre{
    height: 120px;
    background: rgb(255,255,255);
    transition: height 0.1s linear, background 0.1s linear;

}

.scrollNav .navigationBarre{
    background: white;
    height: 80px;
    transition: height 0.5s ease-in, background 0.5s ease-in;

}

.navigationBarre .logo{
    height: 80%;
    margin-left: 10px;
    margin-top: 10px;
}

.scrollNav .navigationBarre .logo{
    height: 80%;
    margin: auto auto auto 10px;
}

.containerGauche{
    height: 100%;
    width: 70%;
    display: flex;
    justify-content: flex-start;
}

.containerDroite{
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 20px;
}
.connexionIcon{
    display: flex;
    align-items: center;
}

.menu {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.menu .MuiButton-root {
    color: black;
    padding: 15px;
    font-family: AvenirNext, serif;
}

.menu .MuiButton-root .active {
    color: #0A90C9;
    font-family: AvenirNext, serif;
}

.menu .MuiButton-root:hover{
    background: rgba(10, 144, 201, 0.1)
}

.navigationBarre a:-webkit-any-link{
    color: black;
}

.onglet{
    text-transform: none;
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.ongletExemple{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    position: relative;
    height: 100%;
}

.ongletExemple .ongletName {
    display: flex;
    align-items: center;
    height: 100%;
}

.MuiButton-root:hover .ongletExemple .sousMenu{
    display: block;
}
.sousMenu{
    background: white;
}
.sousMenu{
    display: none;
    background: white;
    position: absolute;
    width: calc(100% + 30px);
    top: calc(100% + 10px);
}

.sousMenu a:-webkit-any-link {
    padding: 10px 0;
    display: block;
}

.sousMenu a:-webkit-any-link:hover{
    color: #0A90C9;
}

.MuiButton-root.btnDevis{
    text-transform: none;
    font-size: 16px;
    background: #F8E700;
    color: black;
    border-radius: 25px;
    font-family: AvenirNextBold, serif;
    padding: 5px 20px 5px 20px;
    visibility: visible;
}

.MuiButton-root:hover.btnDevis{
    background: rgba(248, 231, 0, 0.5);
}

.scrollNav .btnDevis{
    visibility: visible;
}

@media (max-width: 912px) {
    .nav .navigationBarre{
        height: 50px;
        box-shadow: none;
        transition: none;
        background: white;
    }
    .scrollNav .navigationBarre{
        transition: none;
    }
    .nav .navigationBarre .logo{
        height: 80%;
    }
    .navigationBarre .logo{
        height: 60%;
    }
    .nav .sousMenu{
        background: white;
    }
    .containerDroite{
       width: 60%;
    }
    .containerGauche{
        width: 40%;
    }
    .menu{
        position: absolute;
        top: 100%;
        background: white;
    }
    .MuiButton-root.btnDevis{
        font-size: 10px;
    }
    .onglet{
        font-size: 12px;
    }
    .scrollNav .menu{
        box-shadow: 0px 15px 10px -15px rgba(0,0,0,0.5);
    }
    .menu{
        box-shadow: 0px 15px 10px -15px rgba(0,0,0,0.5);
    }

}