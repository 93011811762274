.CheckBoxConfig{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.textePriseCheck{
    margin-left: 10px;
    margin-right: 10px;
}

/* Responsive */

@media (max-width: 912px) {
    .textePriseCheck{
        margin-bottom:0;
    }

}