
.tutoPiece{
    position: fixed;
    z-index: 1100;
    background: white;
    width: 70%;
    height: auto;
    border-radius: 20px;
    box-shadow: 5px 5px 40px #00000080;
    top: 20%;
}

.fermetureTuto{
    position: absolute;
    right: 10px;
    top: 10px;
}

.fermetureTuto:hover{
    color: #a61414;
    cursor: pointer;
}

.tutoPiece .video{
    width: 80%;
    display: flex;
    justify-content: center;
}

.visible{
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
}

/* Responsive */

@media (max-width: 912px) {

    .tutoPiece .titreDevis{
        font-size: 18px;
        margin: 20px 50px;
    }
    .tutoPiece{
        width: 90%;
        height: 60vh;
    }
    .tutoPiece .video{
        height: 60%;
    }
}