.partenaires{
    min-height: 100px;
    margin-bottom: 20px;
    margin-top: 100px;
}

.imageLogoPartenaire{
    height: 100%;
    max-width: calc(100% / 2);
}

.logocentrer{
    display: flex;
    justify-content: center;
}

.carousel .react-multi-carousel-track {
    display: flex;
    align-items: center;
}