.nomExempleDevis{
    width: 100%;
    height: 400px;
    background: #D90368;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
}

.showroom .nomExempleDevis{
    background-size: 100%;
}

.nom{
    text-align: center;
    font-size: 40px;
    color: white;
    font-family: AvenirNextBold, serif;
    padding: 20px;
    width: 40%;
    margin: auto;
}

/* Responcive */

@media (max-width: 912px) {
    .nom{
        font-size: 20px;
    }

    .showroom .nomExempleDevis{
        background-size: cover;
    }
}