.description{
    /*background: cadetblue;*/
    height-min: 400px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 40px 0 40px 0;
}

.textDescription{
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.jlfmmDescription{
    width: calc(100% / 1.4);
    margin: 20px 40px;
    width: auto;
}

.imageDescription{
    position: relative;
    margin: auto;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: calc(100% + 20px);
}

.jlfmmImage{
    background: darkgrey;
    width: 500px;
    height: 400px;
    position: relative;
}

.jlfmmImage::before{
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    bottom: -40px;
    right: -40px;
    background: #0A90C9;
}

/* Responcive */

@media (max-width: 1024px) {
    .description{
        grid-template-columns: repeat(1, 1fr);
    }
    .jlfmmImage{
        width: 350px;
        height: 300px;
    }
    .jlfmmImage::before{
        bottom: -20px;
        right: -20px;
    }
    .jlfmmDescription{
        padding: 50px;
    }
}

@media (max-width: 912px) {
    .jlfmmImage{
        width: 250px;
        height: 200px;
    }
    .jlfmmDescription{
        padding: 0;
    }
}