.showroom .ellipseRose, .showroom .ellipseBleu{
    height: 1700px;
}

.boutonDevisShowroom{
    background: #f8e700;
    border: none;
    padding: 10px 30px;
    font-size: 2em;
    font-family: AvenirNext;
    border-radius: 50px;
    color: black;
}

.boutonDevisShowroom:hover{
    background: #EADA03;
    cursor: pointer;
    box-shadow: 10px 10px 20px 0px lightgrey;
}

/* Responsive */
@media (min-width: 1700px) {
    .showroom .ellipseRose, .showroom .ellipseBleu{
        height: 1700px;
    }
}

@media (max-width: 912px) {
    .showroom .ellipseRose, .showroom .ellipseBleu{
        height: 2000px;
    }
    .boutonDevisShowroom{
        font-size: 1em;
    }
}