.devisFrame .positionFlex{
    display: flex;
    justify-content: space-between;
}
.devisFrame .styleBtnDevis .MuiButtonBase-root {
    width: 170px;
}

.marginAssistant{
    margin: 20px 0;
}

.espaceBtnFlottant{
    height: 60px;
    display: flex;
    justify-content: center;
}

.btnFlottant{
    width: 80%;
    position: absolute;
    z-index: 1000;
}

.btnFlottantFixed{
    position: fixed;
    bottom: 50px;
}

/* Responsive */

@media (max-width: 912px) {
    .devisFrame .positionFlex{
        font-size: 0.8em;
        margin: 0 5px;
    }
    .devisFrame .styleBtnDevis .MuiButtonBase-root {
        width: 170px;
        height: 35px;
    }
    .marginAssistant{
        margin-bottom: 20px;
    }
    .btnFlottantFixed{
        bottom: 10px;
    }
    .btnFlottant{
        width: 100%;
    }
}
