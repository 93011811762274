.superficie{
    display: flex;
    justify-content: start;
    flex-direction: column;
    font-size: 22px;
    align-items: center;
    margin-bottom: 40px;
}

.sliderPosisition{
    display: flex;
}

.superficie .distance{
    margin-left: 10px;
}

.superficieCheck .checkGeneralePosition{
    background: white;
    margin:auto;
    width: calc(100% - 100px);
    justify-content: center;
}
.contenuEtapeSuperficie{
    min-height: calc(100vh / 2);
}

/* Responsive */

@media (max-width: 912px) {
    .superficie{
        font-size: 12px;
    }
    .sliderPosisition{
        flex-direction: column;
        text-align: center;
    }
    .superficieCheck .checkGeneralePosition{
        width: calc(100% - 80px);
    }
    .contenuEtapeSuperficie{
        min-height: calc(100vh / 1.96);
    }
}