.configGenerale{
    background: white;
    border-radius: 10px;
    width: 100%;
}

.configGenerale .checkGeneralePosition{
    width: 400px;
}

.checkGeneralePosition .MuiFormControl-root{
    display: flex;
    justify-content: center;
}

.elementGenerale{
    display: flex;
    justify-content: space-around;
}

.texteExplication{
    text-align: center;
    margin: 0 20px 10px 20px;
}

.radioBouton .MuiButtonBase-root.MuiRadio-root{
    padding: 0;
}

.radioBouton .MuiFormControlLabel-root{
    margin-bottom: 10px;
    margin-left: 0;

}

.radioBouton .MuiFormControlLabel-root .MuiFormControlLabel-label{
    margin-left: 10px;
}

.textePriseGenerale{
    margin-left: 10px;
    margin-right: 10px;
}

/* Responsive */

@media (max-width: 912px) {
    .elementGenerale{
        flex-direction: column;
        align-items: center;
    }
    .radioBouton .MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 12px;
    }
    .textePriseGenerale{
        margin: 0 0 0 10px;
    }
    .configGenerale .checkGeneralePosition{
        width: 300px;
    }
}