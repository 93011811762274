.tableauDevis .MuiTableCell-root{
    font-family: AvenirNext;
    font-size: 0.9em;
    overflow: auto;
}

.tableauDevis .MuiTableHead-root .MuiTableCell-root{
    font-family: AvenirNextBold;
    background: #0a90c9;
    color: white;
    font-size: 1em;
}

.tableauDevis .MuiOutlinedInput-root{
    font-family: AvenirNext;
}

.tableauDetails .MuiTableCell-root{
    padding: 0;
}

/*Responsive*/

@media (max-width: 912px) {
    .tableauDevis .MuiTableHead-root .MuiTableCell-root{
        font-size: 0.75em;
    }
}