.pointsPositif{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 150px 0 100px;
}

.pointPosPosition{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textPointPos{
    font-size: 40px;
    text-align: center;
    margin: 20px;
}

.animate__animated.animate__fadeInLeft {
    --animate-duration: 5s;
}

/* Responcive */

@media (max-width: 912px) {
    .pointsPositif{
        justify-content: center;
        margin: 50px 0 50px;
    }
    .textPointPos{
        font-size: 20px;
    }
    .pointPosPosition .MuiSvgIcon-root{
        font-size: 30px;
    }
}