.ModifActualite{
    margin-left: 20px;
    overflow-y: scroll;
    height: 100vh;
}

.spacetop{
    margin-top: 40px;
}

.ModifActualite input, .ModifPromo input, .ModifPopup input, .modifAvis input{
    height: 30px;
    font-size: 16px;
    font-family: AvenirNext, sans-serif;
    width: 80%;
    border: 1px solid black;
}

.ModifActualite .espacementbox, .ModifPromo .espacementbox, .ModifPopup .espacementbox{
    margin: 20px 0;
}

.ModifActualite .espacebox, .ModifPromo .espacebox, .ModifPopup .espacebox, .modifAvis .espacebox{
    padding-right: 10px;
    padding-left: 10px;
}

.ModifActualite .espacementbox >div, .ModifPromo .espacementbox >div, .ModifPopup .espacementbox >div, .modifAvis .espacementbox >div{
    margin-bottom: 20px;
}

.ModifActualite .boxtextarea, .ModifPromo .boxtextarea, .ModifPopup .boxtextarea{
    height: 250px;
    width: 80%;
    font-size: 16px;
    font-family: AvenirNext, sans-serif;
    padding: 10px;
}

.ModifActualite .imageActu{
    background-color: darkgrey;
    width: 400px;
    height: 250px;
    box-sizing: border-box;
    position: relative;
}

.ModifActualite .inputPosition{
    width: 70px;
    text-align: center;
}

.ModifActualite .corbeille{
    right: 0;

}