.devis .ellipseRose{
    height: 100%;
}

.devis .ellipseBleu{
    height: 100%;
}

.devisTaillePage{
    width: 80%;
    min-height: 500px;
    margin: auto;
}

.titreEtape{
    margin-top: 20px;
}

.espace{
    padding:50px 0;
}

.espaceTop{
    padding-top: 50px;
}

.contenuEtape{
    min-height: calc(100vh / 2 );
    display: flex;
    justify-content: start;
    flex-direction: column;
}

.hexagoneCentrer{
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    flex: 1;
}


.styleBtnDevis .MuiButtonBase-root {
    background-color: #f8e700;
    color: black;
    font-weight: bold;
    border-radius: 25px;
    padding: 5px 20px;
}

.styleBtnDevis .MuiButton-root:hover{
    background: rgba(248, 231, 0, 0.5);
}

.texteInfoService{
    padding: 20px 20px;
    font-weight: bold;
    font-size: 24px;
}
.boxSuperficie{
    display: flex;
    justify-content: center;
}

.Boxcenter{
    display: flex;
    justify-content: center;
}

.inputAssistant{
    width: 110px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    margin-right: 10px;
    border: 1px black solid;
}

.camera .styleBtnDevis .MuiButtonBase-root {
    background: #0a90c9;
    color: white;
}

.consommationTexte{
    font-size: 26px;
}

.consommation{
    display: grid;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.consommation >div{
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: center;
}

/* Responsive */

@media (min-width: 1600px) {
    .texteInfoService{
        padding: 40px 40px;
    }
    .hexagoneCentrerConstruction{
        display: flex;
        justify-content: end;
        margin-top: 100px;
    }

}

@media (max-width: 912px) {
    .espace{
        padding: 30px 5px;
    }
    .contenuEtape{
        min-height: calc(100vh / 3.5);
    }
    .superficie{
        font-size: 12px;
    }
    .inputAssistant{
        margin-bottom: 5px;
    }
    .devisTaillePage{
        width: 100%;
    }
    .consommationTexte{
        font-size: 18px;
    }
    .consommation{
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
    .texteInfoService{
        font-size: 18px;
    }
    .hexagoneCentrer{
        margin-bottom: 0;
    }
    .hexagoneCentrerConstruction{
        margin-top: 0;
    }
}

