.jlfmmTitre{
    text-align: center;
    font-size: 50px;
    letter-spacing: 5px;
    font-weight: bolder;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-block: 0;
}

/* Responcive */

@media (max-width: 1024px) {
    .jlfmmTitre{
        font-size: 25px;
    }
}