.tailleHexagone.MuiSvgIcon-root{
    width: 230px;
    height: 230px;
}

.devis .tailleHexagone.MuiSvgIcon-root{
    width: 200px;
    height: 200px;
}

.tailleDemiHexagone.MuiSvgIcon-root{
    width: 300px;
    height: 150px;
    margin: 0;
}

.hexaTaille .tailleHexagone.MuiSvgIcon-root{
    width: 130px;
    height: 130px;
}

.showroom .tailleHexagone.MuiSvgIcon-root{
    width: 200px;
    height: 200px;
}
.tailleHexagone.MuiSvgIcon-root:hover{
    cursor: auto;
}

.ShadowHexagone.MuiSvgIcon-root{
    width: 200px;
    height: 200px;
    color: #1976d2;
    filter: drop-shadow(3px 5px 2px #d90368);
}

/* Responsive */
@media (min-width: 1700px) {
    .showroom .tailleHexagone.MuiSvgIcon-root{
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 912px) {
    .tailleHexagone.MuiSvgIcon-root{
        width: 130px;
        height: 130px;
        padding: 0;
    }
    .tailleDemiHexagone.MuiSvgIcon-root{
        width: 100px;
        height: 50px;
    }
    .hexaTaille .tailleHexagone.MuiSvgIcon-root{
        width: 100px;
        height: 100px;
    }
    .showroom .tailleHexagone.MuiSvgIcon-root{
        width: 100px;
        height: 100px;
    }
    .devis .tailleHexagone.MuiSvgIcon-root{
        width: 130px;
        height: 130px;
    }
}