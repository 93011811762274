.centrale .styleBtnDevis .MuiButtonBase-root, .detecteur .styleBtnDevis .MuiButtonBase-root{
    background-color: #0a90c9;
    color: white;
}

.centrale .checkGeneralePosition{
    background: white;
    margin: 20px;
    width: calc((100% - 20px * (2 - 1)) / 2);
    justify-content: start;
}

.detecteur .checkGeneralePosition{
    background: white;
    margin:auto;
    width: calc((100% - 80px)/2.7);
    justify-content: center;
}

.centrale .elementGenerale{
    margin: 0 20px;
}

.contenuEtapeDetecteur,.contenuEtapeCommunication{
    min-height: calc(100vh / 2 );
}

/* Responsive */

@media (max-width: 912px) {
    .centrale .checkGeneralePosition, .detecteur .checkGeneralePosition{
        width: calc(100% - 80px);
    }
    .contenuEtapeDetecteur{
        min-height: calc(100vh / 2.24 );
    }
    .contenuEtapeCommunication{
        min-height: calc(100vh / 2.8 );
    }
    .centrale .elementGenerale{
        margin: 0;
    }
}