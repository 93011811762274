.etapeShowroom{
    width: 80%;
    margin: 150px auto 190px;
}

.gridShowroom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
}

.positionEtape{
    display: flex;
    position: relative;
    width: 500px;
    height: 300px;
    text-align: center;
    align-items: center;
}

.decallageGauche{
    grid-column: 2;
    justify-self: end;
}

.decallageDroite{
    grid-column: 1;
}

.etapeHexa{
    font-size: 0.85em;
    color: white;
    position: absolute;
}

.imageEtape{
    background: #f8e700;
    width: 100%;
    height: 100%;
}

.hexaShowroom{
    position: absolute;
    bottom: -100px;
}
.hexaShowroomGauche{
    left: -100px;
}

.hexaShowroomDroite{
    right: -100px;
}

/* Responsive */
@media (min-width: 1700px) {
    .gridShowroom{
        margin-bottom: 100px;
    }
    .positionEtape{
        width: 600px;
        height: 350px;
    }
    .etapeHexa {
        font-size: 2em;
    }
}

/* Responsive */
@media (max-width: 1300px) {
    .positionEtape{
        width: 500px;
        height: 300px;
    }
    .gridShowroom{
        margin-bottom: 120px;
    }
}

@media (max-width: 912px) {
    .etapeShowroom{
        width: 80%;
        margin: 150px auto 90px;
    }
    .positionEtape{
        width: calc(100% - 50px);
        height: auto;
        text-align: justify;
        margin-bottom: 20px;
    }
    .gridShowroom{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 50px;
        align-items: center;
    }
    .hexaShowroom{
        bottom: -65px;
    }
    .hexaShowroomGauche{
        left: -65px;
    }
    .hexaShowroomDroite{
        right: -65px;
    }
    .etapeHexa {
        width: 80px;
    }
}
