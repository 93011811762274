.supprimerNiveau{
    position: absolute;
    top: 3px;
    right: 5px;
}

.niveauTitre{
    font-size: 22px;
    font-weight: bolder;
    padding: 10px 0;
}

.niveau .niveauTitre, .configGenerale .niveauTitre{
    color: #d90368;
}

.croixStyle:hover{
    color: #d90368;
}

.positionPiece{
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.positionListPiece{
    position: relative;
}

.arrowDropIcon{
    position: absolute;
    bottom: -5px;
}

.arrowDropIcon.MuiSvgIcon-root{
    width: 50px;
}


/* Responsive */

@media (max-width: 912px) {
    .niveauTitre {
        font-size: 16px;
    }
}