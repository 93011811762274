.FormConnexion{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FormConnexion .boxPlacement{
    margin-bottom: 10px;
}

.FormConnexion .boutonEnvoyer{
    margin-top: 60px;
}

.FormConnexion .bouton{
    display: flex;
    justify-content: center;
}

/* Responsive */

@media (max-width: 912px) {
    .FormConnexion .boxPlacement{
        margin-bottom: 0;
    }
    .FormConnexion .boutonEnvoyer{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .FormConnexion .margintop{
        margin: 0;
    }
}