.MuiStepLabel-label.MuiStepLabel-alternativeLabel{
    font-family: AvenirNext;
    font-size: 18px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.Mui-active{
    color: #0a90c9;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.Mui-completed{
    color: #0a90c9;
}


/* Responsive */

@media (max-width: 912px) {
    .MuiBox-root.css-8atqhb {
        width: 400px;
        display: none;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
        font-size: 12px;
    }

}