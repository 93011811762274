.processus{
    --processusColor: '';
    width: 100%;
    min-height: 100px;
}

.processus .MuiTypography-root.MuiTimelineContent-root {
    font-family: AvenirNext;
    font-size: 20px;
}

.processus .MuiTimelineItem-root:before {
    flex: 0;
}

.processus .MuiTimeline-root{
    width: 80%;
    margin: auto;
}

.processus .MuiTimelineDot-root{
    background: var(--processusColor);
}

.processus .MuiTimelineConnector-root{
    background: var(--processusColor);
}

/* Responcive */

@media (max-width: 912px) {
    .processus .MuiTypography-root.MuiTimelineContent-root {
        font-size: 16px;
    }
}