.imagelist{
    width: 400px;
    height: 250px;
    background-color: darkgrey;
    display: flex;
    justify-content: flex-end;
}

.positionListImage{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 20px;
    box-sizing: border-box;
    position: relative;
}

.ModifImages{
    overflow-y: auto;
    height: 100vh;
}

.ModifImages .MuiButtonBase-root{
    background-color: #0a90c9;
}

.positionBoutonImage{
    display: flex;
    justify-content: flex-start;
    margin: 40px 20px;
}

.imageModif{
    width: 400px;
    height: 250px;
}
/* Responcive */

@media (max-width: 912px) {
    .imagelist {
        width: 240px;
        height: 150px;
    }
    .imageModif{
        width: 240px;
        height: 150px;
    }
}