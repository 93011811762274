.recapitulatifDevis .recupererDevis{
    display: flex;
    flex-direction: column;
    margin: auto auto 20px;
}

.positionZonePrix{
    display: flex;
    justify-content: space-evenly;
    position: relative;
    margin-top: 140px;
    margin-bottom: 40px;
    width: 50%;
}

.prixHexa{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.boxPrixDevis{
    border-radius: 10px;
    padding: 100px 20px 20px;
    width: calc(100% - 80px);
}

.borderBleu{
    border: 1px solid #0a90c9;
}

.borderVert{
    border: 1px solid #05ab33;
}

.borderJaune{
    border: 1px solid #FFB100;
}

.borderRose{
    border: 1px solid #d90368;
}

.affichagePrixDevis{
    font-size: 1.5em;
}

.marginSousElement{
    margin-top: 5px;
}

.marginTitre{
    margin-bottom: 10px;
}

.tailleTitre{
    font-size: 22px;
}

.boxRecapitulatif{
    background: white;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
}

.tailleBoxRecapConsommation{
    width: 160px;
}

.positionHexa{
    display: flex;
    justify-content: center;
    width: 100%;
}

.positionHexaCase{
    position: absolute;
    top: -100px;
}

.hexagoneBleu .hexa:hover .iconColorSelec{
    color: #0a90c9;
}

.hexagoneRose .iconColorSelec{
    color: #d90368;
}

.hexagoneRose .hexa:hover .iconColorSelec{
    color: #d90368;
}

.hexagoneVert .iconColorSelec{
    color: #05ab33;
}

.hexagoneVert .hexa:hover .iconColorSelec{
    color: #05ab33;
}

.hexagoneJaune .iconColorSelec{
    color: #FFB100;
}

.hexagoneJaune .hexa:hover .iconColorSelec{
    color: #FFB100;
}

.positionBoxRecap{
    display: flex;
    justify-content: start;
    gap: 20px;
    flex-wrap: wrap;
}

.alignmentBoxRecap{
    display: flex;
    justify-content: start;
}

.texteMateriel > ul > li, .texteService >div >ul >li, .texteService >div >div{
    margin-bottom: 5px;
}

.texteService > ul > li{
    margin-bottom: 10px;
}

.texteMateriel >div >ul{
    margin: 0;
}

.servicesEspace{
    margin-top: 80px;
    text-align: justify;
    padding: 20px;
    width: 50%;
}

.barrerTexte{
    text-decoration: line-through;
    font-size: 0.8em;
}

/* Responsive */

@media (max-width: 912px) {
    .positionZonePrix{
        flex-wrap: wrap;
        margin-top: 70px;
        margin-bottom: 0;
        width: 100%;
    }
    .tailleTitre{
        font-size: 18px;
        text-align: center;
    }
    .positionBoxRecap{
        justify-content: center;
        column-gap:0;
        row-gap: 10px;
    }
    .alignmentBoxRecap{
        justify-content: center;
    }
    .positionHexa{
        flex-wrap: wrap;
    }
    .affichagePrixDevis{
        font-size: 1.6em;
    }
    .prixHexa{
        margin-bottom: 20px;
        margin-top: 50px;
        position: relative;
    }
    .positionHexaCase{
        top: -50px;
    }
    .boxPrixDevis{
        padding: 60px 20px 20px;
    }
    .servicesEspace{
        margin: 0;
        width: 100%;
    }
}