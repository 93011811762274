.corbeille{
    position:absolute;
    margin: 2px;
    border: none;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.corbeille:hover{
    background-color: #F0F8FC;
}