.maps{
    height: 100%;
}

.maps >iframe{
    height: 100%;
    width: 100%;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #0A90C9, #D90368);
}