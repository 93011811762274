.alignementHorizontal >div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.item{
    width: 100%;
}

.positionListeElement{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

/* Responcive */

@media (max-width: 912px) {
    .alignementHorizontal >div{
        flex-direction: column;
    }
}
