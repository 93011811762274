.backgroundVideo{
    width: 100%;
    margin-top: 100px;
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}

.btnDevisVideo{
    position: absolute;
    z-index: 1;
    top: calc((702.09/1652 * 100vw)/1.5);
    right: 0;
    animation: 2s ease-in 1s reverse both running slidein;
}

.btnDevisVideo >img{
    width: 400px;
}

.btnDevisVideo >img:hover{
    width: 450px;
}

.fleche {
    position: absolute;
    z-index: 2;
    right: calc(50% - 50px);
    /*top: calc((702.09/1652 * 100vw) + 45px);  /* 16/9 format d'une vidéo ytb */
    top: calc((850/1475 * 100vw) + 45px);  /* format d'une vidéo normal */
}

.desactiveClick{
    pointer-events: none;
    overflow: visible;
}

/* Animation */

@keyframes slidein { from { right:0; } to { right:-400px; }  }

/* Responsive */

@media (max-width: 912px) {
    .backgroundVideo{
        margin-top: 100px;
    }
    .fleche >img{
        width: 50px;
        height: 50px;
    }
    .fleche{
        right: calc(50% - 25px);
        /*top: calc((702.09/1652 * 100vw) + 75px);  /*16/9 format d'une vidéo ytb + 75px (taille btn/2 = 25px et 50px car menumobile/2)*/
        top: calc((850/1475 * 100vw) + 75px);
    }
    .menumobile{
        height: 100px;
    }
    .btnDevisVideo{
        top: calc((702.09/1652 * 100vw)/1.5 + 70px);
        animation: 1s ease-in 0.5s reverse both running slidein;

    }
    .btnDevisVideo >img{
        width: 150px;
    }
    .btnDevisVideo >img:hover{
        width: 200px;
    }
}