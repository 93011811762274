.btnAjouter{
    color: white;
    background-color: #0a90c9;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    font-size: 30px;
    cursor: pointer;
}
.btnAjouter:hover{
    background-color: #1976d2;
}

@media (max-width: 912px) {
    .btnAjouter{
        font-size: 18px;
    }
}
