.connexion footer{
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
}

.connexion .ellipseRose{
    height: 1100px;
}

.connexion .ellipseBleu{
    height: 1100px;
}

.connexion .corps{
    min-height: 700px;
}

.connexion input{
    border-width: 1px;
}

/* Responsive */
@media (min-height: 1100px) {
    .connexion footer{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@media (max-width: 912px) {
    .connexion .ellipseRose{
        height: 500px;
    }

    .connexion .ellipseBleu{
        height: 500px;
    }
    .connexion .corps{
        min-height: 200px;
    }
    .connexion footer{
        position: relative;
    }
}