.positionPieceHabitation{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;
}

.TitreDescriptionExempleDevis{
    font-size: 26px;
    text-align: center;
    margin-top: 20px;
}

.prixExempleDevis{
    font-size: 1.5em;
    text-align: center;
    margin-top: 40px;
}

/*Responcive*/
@media (max-width: 912px) {
    .positionPieceHabitation{
        gap: 0px;
    }
    .TitreDescriptionExempleDevis{
        font-size: 18px;
    }
    .prixExempleDevis{
        font-size: 1.3em;
        margin-top: 40px;
    }
}