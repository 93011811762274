/* CSS générale pour tout le site */

body {
  margin: 0;
  font-family: AvenirNext, sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Style des titres principaux */

.font{
  font-family: AvenirNext, sans-serif;
  font-size: 1.25em; /*20px*/
}

.titre1{
  text-align: center;
  font-size: 2.2em; /*40px*/
  margin: 50px 20px 20px;
}

.justifier{
  text-align: justify;
}

.center{
  text-align: center;
}

.gras{
  font-family: AvenirNextBold;
}

.borderArrondi{
  border-radius: 20px;
}

.marginCote{
  margin: 0 20px;
}

.marginTop{
  margin-top: 20px;
}

.marginBottom{
  margin-bottom: 20px;
}

.margin{
  margin: 20px;
}

.padding{
  padding: 20px;
}

.padding5{
  padding: 5px;
}

.display{
  display: flex;
}

.boxCentre{
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxEvenly{
  display: flex;
  justify-content: space-evenly;
  align-items: start;
}

a:-webkit-any-link{
  text-decoration: none;
}

.corps{
  margin-top: 80px; /* Taille du menu 80px */
  padding-bottom: 100px;
  margin-bottom: -100px;
  position: relative;
}

/*Icon*/
.iconCase{
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Couleur pour le texte */

.textVert{
  color: #05AB33;
}

.textRose{
  color: #D90368;
}

.textBleu{
  color: #0A90C9;
}

.textMauve{
  color: #841ED4;
}

.textRouge{
  color: #A61414;
}

.textBlanc{
  color: white;
}

.textGris{
  color: #6a6a6a;
}

/* Fèche des carouselles */

.carousel .react-multiple-carousel__arrow{
  background: none;
}

.carousel .react-multiple-carousel__arrow::before{
  color: #0A90C9;
}

.carousel .react-multiple-carousel__arrow:hover{
  background: none;
}

/* Cryptage email*/

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}

/* Ellipse */

.ellipseRose{
  position: absolute;
  width: 100%;
  height: 1200px;
  background: rgba(217, 3, 104, 0.06);
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius:
          1900px 0px 10px 0px / 500px 500px 10px 0px;
}

.ellipseBleu{
  position: absolute;
  width: 100%;
  height: 1200px;
  background: rgba(10, 144, 201, 0.07);
  border-radius:
          0px 1900px 10px 10px / 500px 500px 10px 10px;
}

/* Police personnalisé : AvenirNext en normal, gras et italique */

@font-face {
  font-family: AvenirNext;
  src: url("Globale/Polices/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: AvenirNextBold;
  font-weight: bold;
  src: url("Globale/Polices/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: AvenirNextItalic;
  font-style: italic;
  src: url("Globale/Polices/AvenirNextLTPro-It.otf") format("opentype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Responcive */
@media (max-width: 912px) {
  body{
    font-size: 16px;
  }
  .boxEvenly{
    flex-wrap: wrap;
  }
}

@media (max-width: 850px) {
  body{
    font-size: 1em;
  }
  .titre1{
    font-size: 20px;
  }
  .corps{
    margin-top: 140px;
  }
  .font{
    font-size: 12px;
  }
  .iconCase{
    height: 60%;
  }
}
