.popup{
    position: fixed;
    z-index: 1100;
    background: white;
    width: calc(100%/2);
    min-height: calc(100%/4);
    max-height: calc(100%/2);
    top: calc(100vh /2 - (100%/8));
    left: calc(100% / 2 - (100%/4));
    border-radius: 20px;
    box-shadow: 5px 5px 40px #00000080;
    display: flex;
}

.nomPopup{
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.5em;
    color: #d90368;
    width: calc(100% - 100px);
}

.descriptionPopup{
    margin: 20px 50px;
    text-align: justify;
    white-space: pre-wrap;
    overflow-y: auto;
    justify-self: stretch;
    max-height: calc(100% - 80px);
}

/* Responsive */

@media (max-width: 912px) {
    .popup{
        top: calc(100vh /2 - (100%/4));
        left: calc(100% / 2 - (100%/2 - 10px));
        width: calc(100% - 20px);
    }
    .descriptionPopup{
        height: calc(100% - 90px);
    }
}