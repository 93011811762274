.hexa{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

.hexa:hover{
    cursor: pointer;
}

.allServices{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-content: space-evenly;
    flex-direction: column;
    padding-bottom: 28px;
}

.colonneServices{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: -28px;
}

.colonneServicesDecaller{
    margin-left: 200px;
}

.hexagoneAssisant{
    position: absolute;
    z-index: 2;
}

.iconColorSelec{
    color: #0a90c9;
}

.iconColor{
    color: #d90368;
}

.hexa:hover .iconColorSelec {
    color: #0879a9;
}

.elementHexagone{
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    z-index: 10;
    color: white;
}

.titreService{
    width: 120px;
}

.recupererDevis .titreService{
    width: 100px;
}

/* Responsive */

@media (max-width: 912px) {
    .hexa {
        width: 130px;
        height: 130px;
    }
    .colonneServices{
        margin-bottom: -14px;
    }
    .titreService{
        width: 100px;
    }
    .colonneServicesDecaller{
        margin-left: 100px;
    }
}