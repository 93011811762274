.caseHexagone{
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.element{
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    z-index: 10;
    color: white;
    width: 100px;
}

.styleHexagone{
    filter: drop-shadow(20px 20px 20px var(--color));
}

.hoverStyle:hover{
    cursor: pointer;
}

.caseElement{
    text-align: center;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 40px;
}
.hexagone {
    position: relative;
    width: 200px;
    visibility: hidden;
    -webkit-filter : drop-shadow(25px 35px 15px var(--color));
}
.hexagone:after {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    display: block;
    margin-top: 28.86751%;
    padding-bottom: 86.602%;
}
.hexagone .hexagonemain{
    display: block;
    position: absolute;
    width: 100%;
    padding-bottom: 115.47005%;
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
    z-index: 1;
}
.hexagone .hexagonemainbefore{
    content: "";
    display: block;
    position: absolute;
    visibility: visible;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
    -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
    transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

.nomServiceCase{
  height: 25%;
}

.elementHabitation .nomServiceCase{
  height: 25%;
}

/* Responsive */

@media (max-width: 912px) {
    .caseHexagone{
        width: auto;
        height: auto;
        padding: 20px;
    }
    .hexagone{
        width: 130px;
        filter : drop-shadow(20px 20px 15px var(--color));
    }
    .iconCase .MuiSvgIcon-root{
        font-size: 40px;
    }
    .caseElement{
        margin: 30px 20px;
    }
    .elementHabitation .nomServiceCase{
        height: 30%;
    }
}
