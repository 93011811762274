.tooltipInformation.MuiTooltip-tooltip, .tooltipInformation .MuiTooltip-arrow::before{
    font-family: AvenirNext;
    font-size: 16px;
    text-align: justify;
    min-width: 400px;
    padding: 15px;
}

/* Responsive */

@media (max-width: 912px) {
    .tooltipInformation.MuiTooltip-tooltip, .tooltipInformation .MuiTooltip-arrow::before{
        min-width: 0;
    }
}