.boutonvideo{
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
    width: 80%;
}

.titrevideo{
    width: 80%;
    display: flex;
}

.video{
    width: 80%;
    background-color: darkgrey;
    margin: 20px auto;
}

.ModifVideo {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: 100vh;
}

div.MuiPickerStaticWrapper-root {
    overflow: unset;
    background-color: transparent;
    width: 80%;
}

div .MuiButtonBase-root{
    font-family: AvenirNext, serif;
    font-size: 20px;
    text-transform: none;
}

/* Responcive */

@media (max-width: 912px) {
    div .MuiButtonBase-root{
        font-size: 12px;
    }
}
