.nombreprise{
    display: flex;
    justify-content: start;
    align-items: center;
}

.configPrise{
    background: #e7e7e7;
    border-radius: 10px;
    width: calc((100% - 20px * (2 - 1)) / 2);
    margin-bottom: 20px;
    position: relative;
}

.configPriseAutre{
    background: #e7e7e7;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.nombreprise .styleBtnDevis .MuiButtonBase-root {
    width: 20px;
    min-width: 20px;
    margin: 0;
    height: 20px;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #0a90c9;
    color: white;
}

.quantitePrise{
    margin: 0 5px;
}

.textePrise{
    margin-left: 10px;
    margin-right: 10px;
}
/* Responsive */

@media (max-width: 912px) {
    .configPrise{
        width: 100%;
    }
    .nombreprise{
        margin: 5px 0;
    }
    .textePrise{
        margin: 5px 10px;
    }
}