.actualites{
    background: #F8E700;
    min-height: 50px;
    width: 100%;
    clip-path: polygon(0% 0%, 100% 10%, 100% 100%, 0% 100%);    /* Pour mettre une diagonale au container de fond */
    padding-top: 10px;
    padding-bottom: 50px;
}

.evenement{
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.EvenementImage{
    width: 350px;
    height: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.EvenementImage img {
    width: 90%;
    height: 90%;
}

.nomEvenement{
    padding-bottom: 10px;
}

.texteEvenement{
    flex-grow: 1;
    padding: 20px;
}

@media (max-width: 912px) {
    .evenement{
        justify-content: center;
        flex-wrap: wrap;
    }
}