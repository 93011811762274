.admin{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: start;
}

.listComposant{
    width: 15%;
    flex-grow: 0;
    min-width: 200px;
    border-right: 1px solid black;
}

.listElement{
    width: 15%;
    min-width: 200px;
    border-right: 1px solid black;
    background: rgba(10, 144, 201, 0.07);
    overflow-y: auto;
}

.zoneDeModif{
    flex-grow: 1;
    min-width: 400px;
    background: rgba(10, 144, 201, 0.3);
}

.alignementCol{
    text-align: center;
}

.zoneSelectionne{
    background-color: #0a90c9;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

.ListeElement2{
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    padding-top: 20px;
}

.ListeElement{
    position: relative;
    height: 100%;
    box-sizing: border-box;
}

.survole:hover{
    color: #0a90c9;
    cursor: pointer;
}

.modifbtn .MuiButtonBase-root{
    background-color: #0a90c9;
}

/* Responcive */
@media (max-width: 912px) {
    .admin{
        min-height: 100vh;
        overflow-x: scroll;
    }
    .listComposant{
        min-width: 100px;
    }
    .listElement{
        min-width: 100px;
    }
    .zoneSelectionne{
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        padding-bottom: 10px;
    }
    .zoneSelectionne >div {
        padding-bottom: 10px;
    }
}