.avis{
    min-height: 200px;
    text-align: center;
}

.descriptionAvis{
    margin-right: 15%;
    margin-left: 15%;
}

.etoile{
    margin: 15px;
}
