.donneesClients {
    /*background: hotpink;*/
    width: 100%;
    min-height: 150px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: center;
    font-size: 40px;
    margin: 50px 0;
}

/* Animation */

.animate__animated.animate__fadeIn {
    --animate-duration: 5s;
}

/* Responcive */

@media (max-width: 912px) {
    .donneesClients{
        font-size: 20px;
    }
}
@media (max-width: 500px){
    .donneesClients{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
    }
}