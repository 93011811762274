.envoieMail{
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.envoieMail :focus-visible{
    outline-color: #0A90C9;
}

.containerEnvoieMail{
    width: 70%;
    margin: auto;
}

.titreBoxMail{
    padding: 5px;
}

.envoieMail input{
    width: 100%;
    border-width: 1px;
}

.boxMail{
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.boxMessage{
    height: 300px;
    min-width: 100%;
}

.boxPlacement{
    margin-bottom: 40px;
    padding: 10px;
}

.valide{
    margin-top: 10px;
    color: #05AB33;
}

.invalide{
    margin-top: 10px;
    color: #A61414;
}

.boutonEnvoyer {
    width: 300px;
    position: relative;
    background: linear-gradient(to right, #7ebadc, #e077ae) border-box;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 20px;
    padding: 15px 40px;
    font-weight: bold;
    padding: 20px;
}

.boutonEnvoyer::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background:linear-gradient(to right, #0a90c9, #d90368);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.boutonEnvoyer:hover{
    cursor: pointer;
    background: linear-gradient(to right, #0a90c9, #d90368) border-box;
}

.MuiPaper-root.alert{
    font-size: 20px;
    font-family: AvenirNextBold, serif;
    z-index: 1002;
}

/* Responsive */

@media (max-width: 912px) {
    .boutonEnvoyer{
        width: 50%;
    }
    .MuiPaper-root.alert{
        font-size: 12px;
    }
    .envoieMail input{
        width: 95%;
    }
    .boxMessage{
        min-width: 95%;
    }

}