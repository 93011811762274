.formulaireAvis{
    margin: 20px 0;
}

.formulaireAvis .MuiInputLabel-root{
    font-family: AvenirNext;
}

.formulaireAvis .MuiTextField-root{
    width: 300px;
}

.commentaire .MuiTextField-root{
    width: 600px;
}

.contenueFormulaireAvis{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
}

/* Responsive */

@media (max-width: 912px) {
    .commentaire .MuiTextField-root{
        width: 300px;
    }
}