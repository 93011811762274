.formulaire{
    width: 70%;
    min-height: 100%;
    margin: auto;
}

.boxFormulaire{
    background:rgba(255,255,255,0.5);
    border-radius: 20px;
    margin-bottom: 40px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.boxFormulaireObligatoire {
    text-align: center;
    width: calc((100% - 0px * (3 - 1)) / 3);
}
.boxFormulaire:focus-visible{
    outline-color: #0A90C9;
}

.texteChamp{
    margin: 10px;
    font-size: 24px;
}

.formulaire .boxMail{
    width: 500px;
}

.positionFormulaire{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 10px;
}

.formulaire .box{
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.boxRue{
    width: 800px;
}

.boxNumero{
    width: 100px;
}

.formulaire input{
    border-width: 1px;
}

.boutonFormulaire{
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.boutonFormulaire .MuiButtonBase-root {
    background: #f8e700;
    font-family: AvenirNext;
    color: black;
    border-radius: 25px;
    font-weight: bold;
    padding: 5px 20px;
}

.boutonFormulaire .MuiButton-root:hover{
    background: rgba(248, 231, 0, 0.5);
}

.formulaire .MuiOutlinedInput-input{
    font-family: AvenirNext;
    font-size: 20px;
    width: 300px;
}

.formulaire .MuiFormHelperText-root
{
    font-family: AvenirNext;
}

.formulaire .MuiInputLabel-root
{
    font-family: AvenirNext;
    font-size: 16px;
}

.formulaire .MuiFormControl-root.MuiTextField-root{
    margin: 20px;
}


/* Responsive */

@media (max-width: 912px) {
    .formulaire .boxMail{
        width: auto;
        min-width: 250px;
    }
    .boxRue{
        width: auto;
        min-width: 250px;
    }
    .formulaire .MuiOutlinedInput-input{
        width:200px;
        font-size: 12px;
    }
    .formulaire .MuiInputLabel-root{
        font-size: 16px;
    }
    .boutonFormulaire{
        margin: 20px 5px;
    }
    .boxFormulaire{
        justify-content: start;
        padding: 0;
    }
    .boxFormulaireObligatoire {
        width: 100%;
    }
}