.prixDevis{
    width: 100%;
    margin: auto;
}

.gridDevis{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.prix{
    background: rgba(255,255,255,0.5);
}

.recevoirDevis{
    background: rgba(255,255,255,0.5);
}

.prix{
    position: relative;
    min-height: 600px;
    width: 100%;
    margin: auto;
}

.titreEnvoieDevis{
    font-size: 1.5em;
}

.numeroDevis{
    font-size: 22px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px;
    width: 30%;
    margin:20px auto;
    background-color: white;
}


.aligementContact{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: start;
}

.centerPrix{
    display: flex;
    justify-content: center;
}

.boxPrix{
    display: flex;
    justify-content: start;
    position: relative;
    width: 500px;
}

.prixTotal{
    text-align: center;
    font-size: 30px;
    color: white;
    background: #0a90c9;
    font-weight: bold;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prixTotalTaille{
    height: 100px;
    width: 300px;
    top: 24%;
}

.assistantPlus{
    height: 70px;
    width: 200px;
    top: 32%;
    font-size: 24px;
    margin-left: 170px;
}

.recevoirDevis .boutonFormulaire{
    display: block;
}

.bloqueCopierColler {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chargement{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.affichageCodePromo{
    position: relative;
    background: white;
    margin: 0 20px;
    border-radius: 5px;
    padding: 10px;
}

.prixDevis .fermetureTuto{
    right: 3px;
    top: 3px;
}

.saveDevis{
    display: flex;
    justify-content: center;
}

/*Spinner de chargement*/
.lds-ring {
    display: inline-block;
    position: relative;
    min-height: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    margin: 15px;
    border: 10px solid #d90368;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #d90368 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Responsive */

@media (max-width: 912px) {
    .gridDevis{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    .recevoirDevis{
        justify-content: center;
        width: 100%;
        margin: 0 5px;
    }
    .titreEnvoieDevis{
        font-size: 1.25em;
        margin: 20px;
    }
    .prix{
        min-height: 200px;
        width: 100%;
        margin: 0 5px;
    }
    .prixTotal{
        position: absolute;
        top:calc(100%/5);
        font-size: 20px;
    }
    .assistantPlus{
        height: 51px;
        width: 170px;
        margin-left: 85px;
    }
    .prixTotalTaille{
        height: 51px;
        width: 170px;
    }
    .demiHexagone{
        position: relative;
        top:0;
        right: 0;
        text-align: center;
    }
    .boxPrix{
        width: 250px;
    }
    .numeroDevis{
        width: 50%;
    }
    .chargement{
        width: 100%;
    }
}