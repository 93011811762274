.information{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.information >div{
    width: 80%;
    margin: auto;
}


.information .contact{
    display: flex;
    flex-direction: column;
}

.information .InfoContact .MuiSvgIcon-root{
    color: #D90368;
}

.information a:-webkit-any-link{
    color: black;
}

/* Responsive */

@media (max-width: 912px) {
    .information{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .information .informationContact{
        padding-bottom: 40px;
        padding-top: 40px;
    }
    .information .maps{
        height: 300px;
    }
}