.typeHabitation{
    display: flex;
    justify-content: space-evenly;
}

.positionHabitation{
    display: flex;
    justify-content: space-between;
}

.positionHabitation >div{
    margin-right: 20px;
}

.surface{
    display: flex;
    justify-content: end;
}

.inputSurface{
    width: 50px;
    border: none;

}

.marginTopTexteEtape{
    margin-top: 40px;
}