.ModifPopup{
    margin-left: 20px;
    height: 100vh;
    overflow-y: scroll;
}

.activePopup{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 110px;
    text-align: center;
    margin-top: 10px;
}