.modifAvis{
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    margin-left: 20px;
    height: 100vh;
}

.elementAValider{
    background-color: #EEF7FB;
    overflow-y: auto;
    min-width: 300px;
    border-left: 1px solid black;
}

.titreAvis{
    font-size: 1.5em;
}

.boxtextarea{
    height: 250px;
    width: 500px;
    font-size: 16px;
    font-family: AvenirNext, sans-serif;
    padding: 10px;
}

/* Responsive */

@media (max-width: 912px) {
    .boxtextarea{
        width: 300px;
        margin-right: 20px;
    }
}